import React from "react"
import css from "@styled-system/css"

import {
  AspectRatio,
  Box,
  Flex,
  H3,
  Image,
  RichText,
  Text,
  Wrapper,
} from "components"

const Item = ({ heading, year, description, image }) => (
  <Box>
    <AspectRatio ratio="4:3">
      <Image
        src={image}
        alt={image.alt}
        css={css({ width: "100%", height: "100%" })}
      />
    </AspectRatio>
    <Box>
      <H3 children={heading} mt={[2, null, 3]} />
      <Text
        children={"Completed in " + year}
        as="span"
        mt={1}
        fontSize={0}
        css={css({ display: "block" })}
      />
      <Box width={[16, 32]} height="1px" my={[2, 3]} bg="border" />
      <RichText content={description} mt={1} fontSize={[0, 1]} />
    </Box>
  </Box>
)

const Projects = ({ projects }) => (
  <Wrapper as="section" id="past-success-projects" pt={[5, 6]}>
    <Flex
      flexWrap="wrap"
      css={css({
        mx: [-3, -4],
        my: -3,
        "> *": {
          width: ["100%", "50%", "33.333%"],
          px: [3, 4],
          py: 3,
        },
      })}
    >
      {projects.map(project => (
        <Item
          heading={project.community_name.text}
          year={project.year_completed}
          description={project.description}
          image={project.image}
        />
      ))}
    </Flex>
  </Wrapper>
)

export default Projects
