import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SEO, PageHeader, CallToAction } from "components"
import { usePreviewData } from "utils"

import Projects from "./_projects"

function PastSuccessPage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicPastSuccesses {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            projects {
              community_name {
                text
              }
              description {
                html
              }
              image {
                url
                alt

                fluid(maxWidth: 640) {
                  ...GatsbyPrismicImageFluid
                }
              }
              year_completed
            }
            closing_block_image {
              url
              alt

              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            closing_block_video {
              url
            }
            closing_block_title {
              text
            }
            closing_block_description {
              html
            }
            closing_block_button_text
            closing_block_button_link {
              link_type
              url
            }
            closing_block_style
            closing_block_pattern
            closing_block_color
            closing_block_side
          }
        }
      }
    `
  )

  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicPastSuccesses } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicPastSuccesses.data.seo_title}
        description={prismicPastSuccesses.data.seo_description}
        image={prismicPastSuccesses.data.seo_image}
      />
      <PageHeader
        image={prismicPastSuccesses.data.hero_image}
        video={prismicPastSuccesses.data.hero_video}
        title={prismicPastSuccesses.data.hero_title}
        subtitle={prismicPastSuccesses.data.hero_subtitle}
      />
      <Projects projects={prismicPastSuccesses.data.projects} />
      <CallToAction
        image={prismicPastSuccesses.data.closing_block_image}
        video={prismicPastSuccesses.data.closing_block_video}
        heading={prismicPastSuccesses.data.closing_block_title}
        text={prismicPastSuccesses.data.closing_block_description}
        button={prismicPastSuccesses.data.closing_block_button_text}
        link={prismicPastSuccesses.data.closing_block_button_link}
        style={prismicPastSuccesses.data.closing_block_style}
        pattern={prismicPastSuccesses.data.closing_block_pattern}
        bg={prismicPastSuccesses.data.closing_block_color}
        align={prismicPastSuccesses.data.closing_block_side}
      />
    </>
  )
}

export default PastSuccessPage
